
// Fade in animation
export const initFadeIn = () => {
	const reveal_observer = new IntersectionObserver( ( entries ) => {
		entries.forEach( entry => {
			if ( entry.isIntersecting ) {
				entry.target.classList.add( 'fade-in--reveal' );
			}
		} );
	}, {} );

	const fade_in_elements = document.querySelectorAll( '[data-fade-in]' );

	fade_in_elements.forEach( element => {
		let delay = 0;
		if ( element.dataset.fadeInDelay ) {
			delay = parseInt( element.dataset.fadeInDelay );
		}
		if ( element.dataset.fadeInInstant ) {
			element.classList.add( 'fade-in--reveal' );
			return;
		}
		setTimeout( () => {
			reveal_observer.observe( element );
		}, delay );
	} );
};
